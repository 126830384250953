import {
  AccountDisplayIDModel,
  FileDisplayIDModel,
  ShopDisplayIDModel,
} from '~/models'

export type FileModelJSON = {
  displayID: string
  name: string
  path: string
  type: string
  order: number
}

export type FileModelArgs = {
  accountDisplayID: AccountDisplayIDModel
  shopDisplayID?: ShopDisplayIDModel
  displayID: FileDisplayIDModel
  name: string
  type: string
  order?: number
}

export class FileModel {
  public readonly displayID: FileDisplayIDModel
  public readonly name: string
  public readonly path: string
  public readonly type: string
  public readonly order: number
  public readonly belongsToShop: boolean

  constructor(args: FileModelArgs) {
    this.displayID = args.displayID
    this.name = args.name
    this.type = args.type.toLowerCase().trim()
    this.path = args.shopDisplayID
      ? `${args.accountDisplayID}/${args.shopDisplayID}/${args.displayID}`
      : `${args.accountDisplayID}/${args.displayID}`
    this.order = args.order ?? 0
    this.belongsToShop = args.shopDisplayID ? true : false
  }
  public toJSON(): FileModelJSON {
    return {
      displayID: this.displayID.toJSON(),
      name: this.name,
      path: this.path,
      type: this.type,
      order: this.order,
    }
  }
  public static fromJSON(json: FileModelJSON): FileModel {
    const [accountDisplayID, shopDisplayID, displayID] = FileModel.createDisplayIDs(json.path)
    return new FileModel({
      accountDisplayID: accountDisplayID,
      shopDisplayID: shopDisplayID,
      displayID: displayID,
      name: json.name,
      type: json.type,
      order: json.order,
    })
  }
  public static createDisplayIDs(path: string)
    : [AccountDisplayIDModel, ShopDisplayIDModel | undefined, FileDisplayIDModel] {
    const splited = path.split('/')
    if (splited.length === 0 || splited.length === 1)
      throw new Error(`invlaid path: ${path}, splited: ${splited}`)
    if (splited.length === 2)
      return [
        new AccountDisplayIDModel(splited[0]),
        undefined,
        new FileDisplayIDModel(splited[1])
      ]
    if (splited.length === 3)
      return [
        new AccountDisplayIDModel(splited[0]),
        new ShopDisplayIDModel(splited[1]),
        new FileDisplayIDModel(splited[2]),
      ]
    throw new Error(`invlaid path: ${path}, splited: ${splited}`)
  }
}
