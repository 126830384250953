export class ModelYear {
  public readonly value: number

  constructor(value: number) {
    if (value <= 0) throw new Error(`invalid year: ${value}`)
    this.value = value
  }
  public toString = (): number => this.value
  public toJSON = (): number => this.value
}
