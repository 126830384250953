import { MakerDisplayIDModel } from '~/models'

export type MakerModelJSON = {
  displayID: string,
  name: string,
  aliases: string[],
  description: string,
}

export type MakerModelArgs = {
  displayID: MakerDisplayIDModel,
  name: string,
  aliases?: string[],
  description?: string,
}

export class MakerModel {
  public readonly displayID: MakerDisplayIDModel
  public readonly name: string
  public readonly aliases: string[]
  public readonly description: string

  constructor(args: MakerModelArgs) {
    this.displayID = args.displayID
    this.name = args.name
      .trim()
      .toUpperCase()
      .replace(/(\s)+/g, ' ')
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g,
        (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))
    this.aliases = args.aliases ? args.aliases.map(alias => alias.trim().replace(/(\s)+/g, ' ')) : []
    this.description = args.description ? args.description.trim() : ""
  }

  public toJSON(): MakerModelJSON {
    return {
      displayID: this.displayID.toJSON(),
      name: this.name,
      aliases: this.aliases,
      description: this.description,
    }
  }

  static fromJSON(json: MakerModelJSON): MakerModel {
    return new MakerModel({
      displayID: new MakerDisplayIDModel(json.displayID),
      name: json.name,
      aliases: json.aliases,
      description: json.description,
    })
  }
}
