import {
  BicycleGenreModel,
  BicycleDisplayIDModel,
  MakerModel,
  MakerModelJSON,
  ModelColor,
  ModelName,
  ModelSize,
  ModelYear,
} from '~/models'

export type BicycleModelJSON = {
  displayID: string,
  name: string,
  maker: MakerModelJSON,
  genre: number,
  color: string,
  size: string,
  year: number,
}

export type BicycleModelArgs = {
  displayID: BicycleDisplayIDModel,
  name: ModelName,
  maker: MakerModel,
  genre: BicycleGenreModel,
  color: ModelColor,
  size: ModelSize,
  year: ModelYear,
}

export class BicycleModel {
  public readonly displayID: BicycleDisplayIDModel
  public readonly name: ModelName
  public readonly maker: MakerModel
  public readonly genre: BicycleGenreModel
  public readonly color: ModelColor
  public readonly size: ModelSize
  public readonly year: ModelYear

  constructor(args: BicycleModelArgs) {
    this.displayID = args.displayID
    this.name = args.name
    this.maker = args.maker
    this.genre = args.genre
    this.color = args.color
    this.size = args.size
    this.year = args.year
  }

  public toJSON(): BicycleModelJSON {
    return {
      displayID: this.displayID.toJSON(),
      name: this.name.value,
      maker: this.maker.toJSON(),
      genre: this.genre.toJSON(),
      color: this.color.value,
      size: this.size.value,
      year: this.year.value,
    }
  }

  static fromJSON(json: BicycleModelJSON): BicycleModel {
    return new BicycleModel({
      displayID: new BicycleDisplayIDModel(json.displayID),
      name: new ModelName(json.name),
      maker: MakerModel.fromJSON(json.maker),
      genre: new BicycleGenreModel(json.genre),
      color: new ModelColor(json.color),
      size: new ModelSize(json.size),
      year: new ModelYear(json.year),
    })
  }
}
