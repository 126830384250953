export class ModelName {
  public readonly value: string

  constructor(value: string) {
    this.value = value
      .trim()
      .toUpperCase()
      .replace(/(\s)+/g, ' ')
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g,
        (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))
  }
  public toString = (): string => this.value
  public toJSON = (): string => this.value
}
