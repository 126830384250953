import {
  PartGenreModel,
  PartDisplayIDModel,
  MakerModel,
  MakerModelJSON,
  ModelColor,
  ModelName,
  ModelSize,
} from '~/models'

export type PartModelJSON = {
  displayID: string,
  name: string,
  maker: MakerModelJSON,
  genre: number,
  color?: string,
  size?: string,
}

export type PartModelArgs = {
  displayID: PartDisplayIDModel,
  name: ModelName,
  maker: MakerModel,
  genre: PartGenreModel,
  color?: ModelColor,
  size?: ModelSize,
}

export class PartModel {
  public readonly displayID: PartDisplayIDModel
  public readonly name: ModelName
  public readonly maker: MakerModel
  public readonly genre: PartGenreModel
  public readonly color?: ModelColor
  public readonly size?: ModelSize

  constructor(args: PartModelArgs) {
    this.displayID = args.displayID
    this.name = args.name
    this.maker = args.maker
    this.genre = args.genre
    this.color = args.color
    this.size = args.size
  }

  public toJSON(): PartModelJSON {
    return {
      displayID: this.displayID.toJSON(),
      name: this.name.value,
      maker: this.maker.toJSON(),
      genre: this.genre.toJSON(),
      color: this.color?.value,
      size: this.size?.value,
    }
  }

  static fromJSON(json: PartModelJSON): PartModel {
    return new PartModel({
      displayID: new PartDisplayIDModel(json.displayID),
      name: new ModelName(json.name),
      maker: MakerModel.fromJSON(json.maker),
      genre: new PartGenreModel(json.genre),
      color: json.color ? new ModelColor(json.color) : undefined,
      size: json.size ? new ModelSize(json.size) : undefined,
    })
  }
}
